/**
 * ErrorHandler
 * @module Services/error/ErrorHandler
 * @description services for ErrorHandler
 */

import cookies from 'js-cookie';
import nextCookies from 'next-cookies';
import {setCookie} from 'nookies';

const self = {};

self.COOKIE_ERROR_MESSAGES_TAG = 'error_messages';
self.COOKIE_SUCCESS_MESSAGES_TAG = 'success_messages';

/**
 * Transforms a Yup validation error to a more specific error prefixed with E_ and post-fixed with
 * _{field_name}
 * @author Sameh Bellez
 * @alias normalizeOneValidationError
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} field The field of the error
 * @param   {string} error the error
 * @returns {string}  a more specific error
 */
const normalizeOneValidationError = (field, error) => {
  if (error.indexOf("E_") == 0) {
    return error;
  }
  return 'E_' + error.toUpperCase() + '_' + field.toUpperCase();
};

/**
 * Transforms a set Yup validation errors to a more specific errors prefixed with E_ and post-fixed
 * with
 * _{field_name}
 * @author Sameh Bellez
 * @alias normalizeValidationErrors
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} errors The set of errors
 * @returns {array}  a set of more specific errors
 */
self.normalizeValidationErrors = (errors) => {
  if (!errors) {
    return;
  }
  for (let field in errors) {
    errors[field] = normalizeOneValidationError(field, errors[field]);
  }
  return {errors: errors};
};

/**
 * Sets the errorAction field
 * @author Sameh Bellez
 * @alias setErrorActions
 * @memberof module:Services/error/ErrorHandler
 * @param   {Object} actions The actions
 * @returns {void}
 */
self.setErrorActions = (actions) => {
  self.errorActions = actions;
};

/**
 * Handles a set of errors and sets them to the errorActions field
 * @author Sameh Bellez
 * @alias handleErrors
 * @memberof module:Services/error/ErrorHandler
 * @param   {arrays} errors The errors
 * @returns {void}
 */
self.handleErrors = (errors) => {
  if (!errors || !errors.length || !self.errorActions) {
    return;
  }

  self.errorActions.setStatus(null);
  const status = {};
  const genericErrors = [];
  errors.forEach(error => {
    if (error.field) {
      status[error.field] = error.code;
    } else {
      genericErrors.push(error.code);
    }
  });

  if (genericErrors.length) {
    status.genericErrors = genericErrors;
  }
  self.errorActions.setStatus(status);
};

/**
 * Adds a cookie message
 * @author Sameh Bellez
 * @alias addCookieMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} tag the message tag
 * @param   {string} message The message to save
 * @param   {Object} ctx the http context
 * @returns {void}
 */
const addCookieMessage = (tag, message, ctx) => {
  let cookieMessages;
  if (ctx) {
    cookieMessages = nextCookies(ctx)[tag];
  } else {
    cookieMessages = cookies.get(tag);
  }

  let messages;
  if (cookieMessages) {
    messages = JSON.parse(cookieMessages);
  } else {
    messages = [];
  }

  messages.push(message);
  if (ctx) {
    setCookie(ctx, tag, JSON.stringify(messages), {
      path: '/',
    });
  } else {
    cookies.set(tag, JSON.stringify(messages));
  }
};

/**
 * Adds a cookie error message
 * @author Sameh Bellez
 * @alias addCookieErrorMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} message The message to save
 * @param   {Object} ctx the http context
 * @returns {void}
 */
self.addCookieErrorMessage = (message, ctx) => {
  addCookieMessage(self.COOKIE_ERROR_MESSAGES_TAG, message, ctx);
};

/**
 * Adds a cookie success message
 * @author Sameh Bellez
 * @alias addCookieSuccessMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} message The message to save
 * @param   {Object} ctx the http context
 * @returns {void}
 */
self.addCookieSuccessMessage = (message, ctx) => {
  addCookieMessage(self.COOKIE_SUCCESS_MESSAGES_TAG, message, ctx);
};

/**
 * Gets cookies error messages
 * @author Sameh Bellez
 * @alias addCookieSuccessMessage
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} tag The cookie tag
 * @param   {object} ctx the http context
 * @returns {void}
 */
const getCookieMessages = (tag, ctx) => {
  if (!ctx) {
    return null;
  }
  const messages = nextCookies(ctx)[tag];
  if (!messages) {
    return null;
  }

  return JSON.parse(messages);
};

/**
 * Gets cookies error messages
 * @author Sameh Bellez
 * @alias getCookieErrorMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} ctx the http context
 * @returns {void}
 */
self.getCookieErrorMessages = (ctx) => {
  return getCookieMessages(self.COOKIE_ERROR_MESSAGES_TAG, ctx);
};

/**
 * Gets cookies success messages
 * @author Sameh Bellez
 * @alias getCookieSuccessMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {object} ctx the http context
 * @returns {void}
 */
self.getCookieSuccessMessages = (ctx) => {
  return getCookieMessages(self.COOKIE_SUCCESS_MESSAGES_TAG, ctx);
};

/**
 * Displays a cookie message
 * @author Sameh Bellez
 * @alias displayCookieMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {string} tag The cookie tag
 * @param   {string} type The messages type
 * @param   {array} messages The messages to display
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
const displayCookieMessages = (tag, type, messages, addToast, t) => {
  if (messages && messages.length) {
    messages.forEach((message) => {
      addToast(t(message), {
        appearance: type, autoDismiss: true, pauseOnHover: true
      });
    });
  }
  cookies.remove(tag);
};

/**
 * Displays a cookie error message
 * @author Sameh Bellez
 * @alias displayCookieErrorMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} messages The messages to display
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
self.displayCookieErrorMessages = (messages, addToast, t) => {
  displayCookieMessages(self.COOKIE_ERROR_MESSAGES_TAG, 'error', messages, addToast, t);
};

/**
 * Displays a cookie success message
 * @author Sameh Bellez
 * @alias displayCookieSuccessMessages
 * @memberof module:Services/error/ErrorHandler
 * @param   {array} messages The messages to display
 * @param   {function} addToast The toast display function
 * @param   {function} t Translations
 * @returns {void}
 */
self.displayCookieSuccessMessages = (messages, addToast, t) => {
  displayCookieMessages(self.COOKIE_SUCCESS_MESSAGES_TAG, 'success', messages, addToast, t);
};

module.exports = self;
