/**
 * LinksProvider
 * @module Services/http/LinksProvider
 * @name global.LinksProvider
 * @description Provides the application uris
 */

const self = {};

/**
 * Get a link after injecting its parameters
 * @name LinksProvider.get
 * @author Sameh Bellez
 * @alias get
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted endpoint url
 */
self.get = (endpoint, params) => {
  if (!params) {
    return endpoint;
  }

  let result = endpoint;

  for (let field in params) {
    result = result.replace(`:${field}`, params[field]);

  }

  return result;
};

/**
 * Gets a link with query params after injecting its parameters
 * @author Seif Khiari
 * @alias getRouteWithQuery
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted endpoint url
 */
self.getRouteWithQuery = (route, params) => {
  if (!params) {
    return route;
  }
  let result = route;
  for (let field in params) {
    if (params[field] != null) {
      result = result.replace(`:${field}`, encodeURIComponent(params[field]));
    } else {
      result = result.replace(`${field}=:${field}`, '');
      result = result.replace(`:${field}`, '');
    }
  }
  return result;
};

/**
 * Get a full link after injecting its parameters
 * @name LinksProvider.getFull
 * @author Sameh Bellez
 * @alias getFull
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted full endpoint url
 */
self.getFull = (endpoint, params) => {
  return process.env.REACT_BACKEND_BASE_URL + self.get(endpoint, params);
};

/**
 * Get Frontend full link after injecting its parameters
 * @name LinksProvider.getFullRoute
 * @author Seif Khiari
 * @alias getFullRoute
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted full endpoint url
 */
self.getFullRoute = (endpoint, params) => {
  return process.env.REACT_FRONTEND_BASE_URL + self.get(endpoint, params);
};

/**
 * Get M1L Frontend full link after injecting its parameters
 * @name LinksProvider.getFullM1lRoute
 * @author Seif Khiari
 * @alias getFullM1lRoute
 * @memberof Services/http/LinksProvider
 * @param   {endpoint} The endpoint string
 * @param   {object} The params list
 * @returns {string} The formatted full endpoint url
 */
self.getFullM1lRoute = (endpoint, params) => {
  return process.env.REACT_M1L_FRONTEND_BASE_URL + self.get(endpoint, params);
};


/**
 * Get a full link after injecting its parameters
 * @name LinksProvider.API
 */
self.API = {
  AUTH: {
    FACEBOOK: '/auth/facebook',
    GOOGLE: '/auth/google',
    SIGN_IN: '/auth/signin',
    SIGN_UP: '/auth/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/forgot-password/:reference',
    UPDATE_USER_PASSWORD: '/auth/update-password',
    ACTIVATE_ACCOUNT: '/users/activate-account/:reference',
    RESEND_ACTIVATE_ACCOUNT: "/users/resend-activation-link"
  }, POTS: {
    POTS: '/pots',
    POT: '/pots/:reference',
    PARTICIPANTS: '/pots/:reference/participants',
    TMP_IMAGE: '/pots/tmp-picture',
    DESC_IMAGE: '/pots-description-images',
    CONTACT_ORGANIZER: '/pots/:reference/contact-organizer',
    GREETING_CARD: {
      GREETING_CARD: '/pots/:reference/greeting-card',
      GREETING_CARD_MESSAGES: '/pots/:reference/greeting-card/messages',
      GREETING_CARD_MESSAGE: '/pots/:reference/greeting-card/messages/:messageReference',
      GREETING_CARD_MESSAGE_PDF: '/pots/:potReference/greeting-card/pdf'
    },
    OFFER_REQUEST: '/pots/:reference/offer-request',
    GIVEAWAY_CONFIRM: '/pots/:potReference/offer-confirmation/:reference',
    POT_BENEFICIARY: '/pots/:reference/offered',
    UPDATE_STATUS: '/pots/:reference/update-status',
    CANCEL_GIVEAWAY_REQUEST: '/pots/:reference/cancel-offer-request',
    THANK_PARTICIPANTS: '/pots/:reference/thank-participants',
    CASH_OUT: '/pots/:reference/cash-outs',
    CASH_OUT_CONFIRM: '/pots/:reference/cash-outs/:cashOutReference'
  }, PRODUCTS: {
    PRODUCT: '/products/:reference',
    SEARCH: '/products?categories=:categories&age_ranges=:age_ranges&type=:type&gender=:gender&min_price=:min_price&max_price=:max_price&sort_criteria=:sort_criteria&is_favorite=:is_favorite&limit=:limit&offset=:offset'
  }, PROMO_CODES: {
    PROMO_CODE: '/promo-codes/:reference'
  }, PARTICIPATIONS: {
    PARTICIPATIONS: '/pots/:reference/participations',
    PARTICIPATION: '/pots/:id/participations/:orderId',
    UPDATE: '/pots/:id/participations/:orderId',
    RECEIPT: '/participations/:orderId/receipt/:tabName'
  }, USER_ACTIONS: {
    USER_ACTION: '/user-actions/:reference'
  }, USERS: {
    USERS: '/users',
    USER_PROFILE: '/users/profile',
    TMP_AVATAR: '/users/tmp-avatar',
    NOTIFICATION: '/users/notifications/:reference',
    NOTIFICATIONS: '/users/notifications',
    USER_ACCOUNT_STATUS: '/users/dashboard-info',
    USER_CREATE_RIB: '/users/add-rib',
    USER_GET_RIB: '/users/ribs',
    USER_DELETE_RIB: '/users/ribs/:reference',
    USER_OPTINS: '/users/optins',
    UPDATE_EMAIL: '/users/update-email',
    CONFIRM_EMAIL_UPDATE: '/users/update-email-confirmation/:reference',
    USER_POT: '/users/pots',
    USER_SPENDING: '/users/expenditures',
    USER_CHECK_EXPENDITURES: '/users/has-expenditures'

  }, USER: {
    UPDATE_USER: '', USER_EMAIL_UPDATE: '', USER_ADD_RIB: '/users/add-rib'
  }, COMMERCIAL_BANNER: {
    DETAILS: '/commercial-banners/:type'
  }, PAGES_DATA: {
    PAGES_DATA: '/pages-data'
  }, GIFT_CARDS: {
    GENERATE: '/pots/:reference/gift-cards',
    GET_ORDER: '/pots/:potReference/gift-cards/:orderReference',
    RESEND_GIFT_CARD: '/pots/:potReference/gift-cards/:orderReference/:giftCardReference',
  }, KYC: {
    USERS: '/users/kyc/users',
    ME: '/users/kyc/me',
    SEND: '/kyc/send'
  }, FILES: {
    FILES: '/files',
    FILE: '/files/:file_name'
  }
};

/**
 * Get a full link after injecting its parameters
 * @name LinksProvider.ROUTES
 */
self.ROUTES = {
  HOME: '/',
  FAQ: '/faq',
  SPENDING_INFORMATION: "/depenser",
  TERMS: "/conditions-generales-utilisation",
  COOKIES: "/utilisation-des-cookies",
  AUTH: {
    SIGN_IN: '/connexion',
    SIGN_UP: '/inscription'
  },
  CONCEPT: "/concept",
  ACCOUNT: {
    DASHBOARD: '/tableau-de-bord',
    POT_BOARD: '/mes-cagnottes',
    SPENDING_BOARD: '/mes-depenses',
    DOCUMENTS: '/mes-documents'
  },
  POT: {
    CREATE: '/creer-une-cagnotte',
    POT: '/cagnotte/:reference',
    POT_BENEFICIARY: '/cagnotte/:reference/beneficiaire/:email',
    PARTICIPATE: '/cagnotte/:reference/participer',
    MANAGE: '/cagnotte/:reference/modification',
    GIVEAWAY: '/cagnotte/:reference/' + 'offrir',
    WITHDRAWAL: '/cagnotte/:reference/demander-un-virement',
    WITHDRAWAL_CONFIRM: '/cagnotte/:reference/demander-un-virement/:cashOutReference',
    USE_MONEY: '/cagnotte/:reference/utiliser-la-cagnotte',
    CREATE_GIFT_CARD: '/cagnotte/:reference/depenser/carte-cadeau',
    CREATE_GIFT_CARD_CONFIRMATION: '/cagnotte/:reference/depenser/carte-cadeau/:orderReference/confirmation',
    GREETING_CARD: '/cagnotte/:reference/carte-de-voeux',
    GREETING_CARD_MESSAGE_CREATE: '/pots/:reference/greeting-card-messages',
    GREETING_CARD_MESSAGE_UPDATE: '/pots/:potReference/greeting-card-messages/:greetingCardMessageReference',
    GREETING_CARD_MESSAGE_DELETE: '/pots/:potReference/greeting-card-messages/:greetingCardMessageReference'
  },
  PRODUCT: {
    PRODUCT: '/sinspirer/:reference/:productName',
    PRODUCT_WITH_SELECTED_POT: '/sinspirer/:reference/:productName?selectedPot=:selectedPot',
    SEARCH: '/sinspirer?&limit=:limit&offset=:offset&categories=:categories&age=:age&gender=:gender&min_price=:min_price&max_price=:max_price&sort=:sort&favorite=:favorite&selected_pool=:selected_pool',
    SEARCH_MAX_AMOUNT: '/sinspirer?max_price=:max_price',
    SEARCH_NO_PARAMS: '/sinspirer',
    CREATE_GIFT_CARD: '/sinspirer/:reference/:productName/carte-cadeau?potReference=:potReference',
    CREATE_GIFT_CARD_NO_POT: '/sinspirer/:reference/:productName/carte-cadeau',
    CREATE_GIFT_CARD_CONFIRMATION: '/sinspirer/:reference/:productName/carte-cadeau/:potReference/confirmation/:orderReference'
  },
  OTHER: {
    M1L: 'https://www.1001listes.fr'
  },
  M1L : {
    HOME: '/',
    LIST_PAGE : '/liste/:reference',
    DASHBOARD: '/tableau-de-bord'
  }
};

export default self;
