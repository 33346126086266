/**
 * LogService
 * @module Services/utils/LogService
 * @description Offers utility functions to generate, format and print log messages
 */

import moment from 'moment';
import AuthService from "../domain/AuthService";

const getIp = require('../../services/http/IpService');


const self = {};

self.TRAFFIC_TYPES = {
  REQUEST: 'Request',
  RESPONSE: 'Response',
  UNKNOWN: 'Unknown'
};

/**
 * @author Ghassen Manai
 * @alias logHttpTraffic
 * @memberof module:Services/utils/LogService
 *
 * @param {object} ctx An object representing the current context
 * @param {string} url The url of the http request/response
 * @param {string} method The http method used when sending the http request
 * @param {object} any additional information that needs to be logged
 *
 * @returns No return value
 */

self.logHttpTraffic = (ctx, traffic_type, url = undefined, method = undefined, other_info = {}) => {
  const ip = ctx && ctx.req ? getIp(ctx.req) : 'Unknown';
  const dateTime = `[${moment().format('YYYY-MM-DD HH:mm:ss')}]`;
  const principal = ctx ? AuthService.getPrincipal(ctx) : undefined;
  let user = 'Guest';
  if (principal) {
    user = principal.email;
  }
  let message = `${dateTime} [INFO] [${traffic_type}] Ip: ${ip}, User: ${user}, Url: ${url}, Method: ${method.toUpperCase()}`;
  for (let key in other_info) {
    message += `, ${key.charAt(0).toUpperCase() + key.slice(1)}: ${other_info[key]}`;
  }
  console.log(message);
};

export default self;
