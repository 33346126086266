/**
 * AuthService
 * @module Services/domain/AuthService
 * @description Offers a set of methods to help with authentication
 */
import {destroyCookie, setCookie} from "nookies";
import HttpService from '../http/HttpService';
import LinksProvider from '../http/LinksProvider';
import jwt_decode from 'jwt-decode';
import cookies from 'js-cookie';
import nextCookies from 'next-cookies';

let self = {};

/**
 * Checks if the current user is authenticated
 * @author Sameh Bellez
 * @alias isAuthenticated
 * @memberof module:Services/domain/AuthService
 * @returns {boolean}  Whether the current user is connected or not
 */
self.isAuthenticated = () => {
  if (!!cookies.get(process.env.REACT_ACCESS_TOKEN_TAG)
    && (new Date().getTime() / 1000)
    > jwt_decode(cookies.get(process.env.REACT_ACCESS_TOKEN_TAG)).exp) {
    cookies.remove(process.env.REACT_ACCESS_TOKEN_TAG);
    return false;
  } else {
    return !!cookies.get(process.env.REACT_ACCESS_TOKEN_TAG);
  }
};

/**
 * Gets the current access token from cookiess
 * @author Sameh Bellez
 * @alias getToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getToken = () => {
  return cookies.get(process.env.REACT_ACCESS_TOKEN_TAG);
};

/**
 * Gets the current access token from cookiess
 * @author Sameh Bellez
 * @alias getToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getKycToken = () => {
  return cookies.get(process.env.REACT_KYC_ACCESS_TOKEN_TAG);
};

/**
 * Gets the current access token from server cookiess
 * @author Sameh Bellez
 * @alias getServerToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getServerToken = (ctx) => {
  return nextCookies(ctx)[process.env.REACT_ACCESS_TOKEN_TAG];
};

/**
 * Gets the current access token from server cookiess
 * @author Sameh Bellez
 * @alias getServerToken
 * @memberof module:Services/domain/AuthService
 * @returns {string}  The access token
 */
self.getKycServerToken = (ctx) => {
  return nextCookies(ctx)[process.env.REACT_KYC_ACCESS_TOKEN_TAG];
};

/**
 * Saves a token into localStorage
 * @author Sameh Bellez
 * @alias saveAuthToken
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.saveAuthToken = (token, rememberMe, ctx) => {
  if (!token) {
    return;
  }

  const cookieOptions = {
    path: '/'
  };

  cookieOptions.expires = (jwt_decode(token).exp - (new Date().getTime() / 1000)) / 60 / 60/ 24;

  if (ctx) {
    setCookie(ctx, process.env.REACT_ACCESS_TOKEN_TAG, token, {
      path: "/"
    });
  } else {
    cookies.set(process.env.REACT_ACCESS_TOKEN_TAG, token, cookieOptions);

  }
};

/**
 * Saves kyc token into cookies
 * @author Sameh Bellez
 * @alias saveKycToken
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.saveKycToken = (token, ctx) => {
  if (!token) {
    return;
  }

  const maxAge = 60 * 60 * 24 * 365;
  const expires = 365;

  const cookieOptions = {
    path: '/', domain: process.env.REACT_KYC_DOMAIN
  };

  if (ctx) {
    setCookie(ctx, process.env.REACT_KYC_ACCESS_TOKEN_TAG, token, {...cookieOptions, maxAge});
  } else {
    cookies.set(process.env.REACT_KYC_ACCESS_TOKEN_TAG, token, {...cookieOptions, expires});
  }
};
/**
 * Removes the token from the localStorage
 * @author Sameh Bellez
 * @alias removeTokens
 * @memberof module:Services/domain/AuthService
 * @returns {void}
 */
self.removeTokens = () => {
  cookies.remove(process.env.REACT_ACCESS_TOKEN_TAG, {path: '/'});
  cookies.remove(process.env.REACT_KYC_ACCESS_TOKEN_TAG, {
    path: '/',
    domain: process.env.REACT_KYC_DOMAIN
  });
};

/**
 * Gets the connected principal
 * @author Sameh Bellez
 * @alias getPrincipal
 * @memberof module:Services/domain/AuthService
 * @returns {object} the current principal
 */
self.getPrincipal = (ctx) => {
  if (ctx && ctx.req) {
    const tokenCookie = nextCookies(ctx)[process.env.REACT_ACCESS_TOKEN_TAG];
    if (tokenCookie) {

      const tokenObj = jwt_decode(tokenCookie);

      if ((new Date().getTime() / 1000) > tokenObj.exp) {
        destroyCookie(ctx, process.env.REACT_ACCESS_TOKEN_TAG);
        return null;
      } else {
        return tokenObj.user;
      }
    }
  }

  if (!self.isAuthenticated()) {
    return null;
  }
  const data = jwt_decode(self.getToken());
  return data.user;
};

/**
 * Logs out the connected user
 * @author Sameh Bellez
 * @alias logout
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the log out result
 */
self.logout = () => {
  return new Promise((resolve) => {
    self.removeTokens();
    resolve();
  });
};

/**
 * Logs in a user
 * @author Sameh Bellez
 * @alias signin
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the log in result
 */
self.signin = (request) => {
  return HttpService().post(LinksProvider.API.AUTH.SIGN_IN, request)
    .then(function (response) {
      self.saveAuthToken(response.token, request.remember_me);
      return response;
    });
};

/**
 * Signs up a user
 * @author Sameh Bellez
 * @alias signup
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the sign up result
 */
self.signup = function (request) {
  return HttpService().post(LinksProvider.API.AUTH.SIGN_UP, request)
    .then(function (response) {
      self.saveAuthToken(response.token, false);
      return response;
    });
};

/**
 * Signs up a user via social media
 * @author Sameh Bellez
 * @alias socialSignup
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the sign up result
 */
self.socialSignup = function (endpoint, request) {
  return HttpService().post(endpoint, request)
    .then((response) => {
      self.saveAuthToken(response.token, false);
      return response;
    });
};

/**
 * Creates a password forgotten request
 * @author Sameh Bellez
 * @alias generatePasswordForgottenRequest
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the request result
 */
self.generatePasswordForgottenRequest = function (request) {
  return HttpService().post(LinksProvider.API.AUTH.FORGOT_PASSWORD, request)
    .then((response) => {
      return response;
    });
};

/**
 * Resets a password for a user
 * @author Sameh Bellez
 * @alias resetPassword
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the request result
 */
self.resetPassword = function (reference, request) {
  return HttpService().post(LinksProvider.get(LinksProvider.API.AUTH.RESET_PASSWORD, {reference: reference}), request)
    .then((response) => {
      return response;
    });
};

/**
 * Update password for a user
 * @author Romain Paris
 * @alias updatePassword
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the request result
 */
self.updatePassword = function (request) {
  return HttpService().put(LinksProvider.get(LinksProvider.API.AUTH.UPDATE_USER_PASSWORD), request)
    .then((response) => {
      return response;
    });
};

/**
 * Activates an account
 * @author Hassen Charef
 * @alias activateAccount
 * @memberof module:Services/domain/AuthService
 * @returns {promise} A promise containing the request result
 */
self.activateAccount = function (reference, request) {
  return HttpService().get(LinksProvider.get(LinksProvider.API.AUTH.ACTIVATE_ACCOUNT, {reference: reference}), request)
    .then((response) => {
      return response;
    });
};

export default self;
