/**
 * IpService
 * @module Services/http/IpService
 * @name IpService
 * @description Returns the IP from the request IpService
 */


/**
 * Returns the IP from the request IpService
 * @author Hassen Charef
 * @alias getIp
 * @name getIp
 * @memberof module:Services/http/IpService
 * @param   {object} req The request config
 * @returns {object} The IP address
 */
function getIp(req) {
  if (!req) {
    return "NO IP FOUND";
  }
  // For Production env
  if (req.headers['x-real-ip']) {
    return req.headers['x-real-ip'];
  }
  if (req.headers['x-forwarded-for']) {
    return req.headers['x-forwarded-for'];
  }

  // For INT & STAGING env
  if (req.headers['cf-connecting-ip']) {
    return req.headers['cf-connecting-ip'];
  }

  if (req.headers['x-client-ip']) {
    return req.headers['x-client-ip'];
  }
  return req.ip;
}

module.exports = getIp;
